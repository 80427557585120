
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Assigner le scan">
     <!-- form -->
      <validation-observer ref="loginValidation">
        <b-form
          class="auth-user-form mt-2"
          @submit.prevent
        >
    

      
         <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Utilisateur"
              label-for="Utilisateur"
            ><br />

         
          <b-form-select required v-model="form.user.id" :options="userOptions"></b-form-select>

          
         
          </b-form-group>
        </b-col>

        
      </b-row>
      
      

       <b-button
        @click="updateScan"
        >
        Enregistrer
        </b-button>

       
      
      </b-form>
          </validation-observer>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton,BFormInput, BOverlay, BRow, BCol, BFormGroup,BFormFile, BCard,BFormSelect, BFormCheckbox, BFormTextarea, BForm} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import store from '@/store/index'
import UserForm from './UserForm.vue'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'

export default {
  name: 'AddScan',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    vSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm,
    UserForm
  },
  data() {
      return {
        userForm:{},
          newUser:false,
          currentBase64:null,
          measurepoints_file: null,
          measures_file: null,
          pointcloud_file: null,
          edition:false,
          loading:true,
          validation: true,
          userOptions:[],
          form:{
            name:"",
            date:"",
            main_user:{id:localStorage.id_user_senseye_auth},
            measurements:null,
            coordinates:null,
            measure_coordinates:null,
            user:{id:null}
            
          },
          required
      }
  },
  
  methods:{
    
      async getUsers() {
        let users=await Auth.list_users()
        this.userOptions=[]
        for(let i=0;i<users.length;i++) {
          let item = { value: users[i].id, text:  users[i].name+' '+ users[i].surname }
          this.userOptions.push(item)
        }
        //this.form.user.id=users[0].id

      },

      async updateScan()
      {
        this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          this.loading=true
          

            
           try{           
              await APIRequest.update('scans',this.$route.params.id,this.form)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Le scan a bien été modifié !',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                  this.$router.push({name:'scans'})
                }
           catch(error)
           {
             this.loading=false
             this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Une erreur est survenue.',
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
             
           }

        }
        })
      },
      
     
      
  },
  async mounted(){
    
    this.form = await APIRequest.detail('scans',this.$route.params.id)
    let scan=this.form
    this.form.user={id:scan.user.id}
    await this.getUsers()
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>